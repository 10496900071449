/** 0 = OutageInitiation 1 = OutagePlan 2 = OutageReport 3 = ManualRBS 4 = RBS */
export enum DocumentType {
    OutageInitiation = 0,
    OutagePlan = 1,
    OutageReport = 2,
    ManualRBS = 3,
    RBS = 4,
}

export class OutageFile {
    name?: string;
    date?: Date;
    createdBy?: string;
    documentSource?: string;
    downloadUrl?: string;
    documentType?: DocumentType;
    revisionNumber?: number;

    public constructor(data?: OutageFile) {
        this.name = data?.name;
        this.date = data?.date;
        this.createdBy = data?.createdBy;
        this.documentSource = data?.documentSource;
        this.downloadUrl = data?.downloadUrl;
        this.documentType = data?.documentType;
        this.revisionNumber = data?.revisionNumber;

    }
}
