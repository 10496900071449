import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';

@Component({
    name: 'toast-message',
})

export default class ToastMessage extends Vue {
    $pui: any;

    private created(): void {
        EventBus.$on(EventBus.GLOBAL.SHOW_TOAST_MESSAGE, (message = '', messageType = ToastMessageTypes.INFO, duration = 5000, messageTitle = '') => {
            const title = messageType === ToastMessageTypes.ERROR && messageTitle === ''
                ? Vue.prototype?.$translationManager?.vueI18n.t('error')
                : messageTitle;
            this.$pui.toast({
                title: title,
                copy: message,
                type: messageType,
                autoDismiss: duration,
                keepOnHover: true
            });
        });
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.SHOW_TOAST_MESSAGE);
    }
}
