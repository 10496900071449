import { ErrorResponse, AddEditActivity, OutageActivityListItem,
    RiskAssessmentDescription, RiskAssessmentImpact, RiskAssessmentRankingThresholds,
    ActivityUpdateGeneralInformationRequest, SubActivityUpdateRequest, SubActivityAddRequest
} from '@/models';
import MessageHandler from '@/utils/message-handler';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import { setIsLoading } from '@/utils/helpers/app-loading';
import { ActivityMode } from '@/components/outage-details-tables/types';
import Vue from 'vue';
import { activityApi, riskAssessmentApi } from '@/services';

class ActivityService {
    public async getActivity(id: number): Promise<AddEditActivity> {
        setIsLoading(true);
        try {
            const activity = (await activityApi.getActivityGeneralInformation(id))?.result;
            return new AddEditActivity(activity);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new AddEditActivity();
        } finally {
            setIsLoading(false);
        }
    }

    public async mapSubActivityFromParent(parentId: number): Promise<AddEditActivity> {
        setIsLoading(true);
        try {
            const parentActivity = (await activityApi.getActivityGeneralInformation(parentId))?.result;
            return AddEditActivity.mapSubActivityFromParent(parentActivity);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new AddEditActivity();
        } finally {
            setIsLoading(false);
        }
    }

    public async updateActivity(addEditActivity: AddEditActivity, activityMode: ActivityMode): Promise<boolean> {
        setIsLoading(true);
        try {
            const addEditActivityRequestData = AddEditActivity.mapActivityUpdateModel(addEditActivity);
            const parentActivityId = `${addEditActivity?.parentActivityId}`;
            const id = `${addEditActivity?.id}`;

            switch(activityMode) {
            case ActivityMode.EditActivity:
                await activityApi.activityUpdateGeneralInformation(id, addEditActivityRequestData as ActivityUpdateGeneralInformationRequest);
                break;
            case ActivityMode.AddSubActivity:
                await activityApi.addSubActivity(parentActivityId, addEditActivityRequestData as SubActivityAddRequest);
                break;
            case ActivityMode.EditSubActivity:
                await activityApi.updateSubActivity(parentActivityId, id, addEditActivityRequestData as SubActivityUpdateRequest);
                break;
            }
            MessageHandler.showToastMessage(
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.addEditActivity.successfullySavedMessage') as string,
                ToastMessageTypes.SUCCESS,
                undefined,
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.addEditActivity.successfullySaved') as string)
            return true;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return false;
        }
        finally {
            setIsLoading(false);
        }

    }

    public async getSubActivities(id: number): Promise<OutageActivityListItem[]> {
        setIsLoading(true);
        try {
            const subActivityes = (await activityApi.getActivitySubActivities(id))?.result?.items;
            return subActivityes?.map((item: any) => new OutageActivityListItem(item)) ?? [];
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }

    public async getRiskAssessmentDesccriptionTypes(): Promise<RiskAssessmentDescription> {
        setIsLoading(true);
        try {
            const descriptions = (await riskAssessmentApi.riskAssessmentDesccriptionTypes())?.result?.data;
            return new RiskAssessmentDescription(descriptions);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new RiskAssessmentDescription();
        } finally {
            setIsLoading(false);
        }
    }

    public async getRiskAssessmentImpactTypes(): Promise<RiskAssessmentImpact> {
        setIsLoading(true);
        try {
            const impacts = (await riskAssessmentApi.riskAssessmentImpactTypes())?.result?.data;
            return new RiskAssessmentImpact(impacts);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new RiskAssessmentImpact();
        } finally {
            setIsLoading(false);
        }
    }

    public async calculateCostImpactScore(globalId?: number, duration?: number, capacityLoss?: number, materialCost?: number): Promise<number | undefined> {
        try {
            return (await riskAssessmentApi.costImpactCalculation(globalId, duration, capacityLoss, materialCost))
                ?.result
                ?.costImpact;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return undefined;
        }
    }

    public async deleteSubactivity(subactivityName: string | undefined, parentActivityId: number, subactivityId: number): Promise<boolean> {
        try {
            setIsLoading(true);
            await activityApi.deleteSubActivity(parentActivityId, subactivityId);
            const name = subactivityName ?? '';
            MessageHandler.showToastMessage(
                name + ' ' + Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.table.successfullyDeleted') as string, ToastMessageTypes.SUCCESS);
            return true;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return false;
        } finally {
            setIsLoading(false);
        }
    }

    public async syncActivities(outageId: string): Promise<boolean> {
        try {
            setIsLoading(true);
            await activityApi.syncActivities(outageId);
            MessageHandler.showToastMessage(
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.table.activitiesSuccessfullyRefreshed') as string, ToastMessageTypes.SUCCESS);
            return true;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return false;
        } finally {
            setIsLoading(false);
        }
    }

    public async getRiskAssessmentRankingThresholds(technologyId?: number): Promise<RiskAssessmentRankingThresholds> {
        try {
            setIsLoading(true);
            const riskAssessmentRankingThresholds = (await riskAssessmentApi.riskAssessmentRankingThresholds(technologyId))?.result?.data;
            return new RiskAssessmentRankingThresholds(riskAssessmentRankingThresholds);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new RiskAssessmentRankingThresholds();
        } finally {
            setIsLoading(false);
        }
    }
}

export const activityService = new ActivityService();
