export class OutageListItem {
    outageId?: string;
    location?: string;
    name?: string;
    title?: string;
    startDate?: Date;
    endDate?: Date;
    duration?: number;
    isNew?: boolean;
    isUpdated?: boolean;
    cost?: number;
    currency?: string;

    public constructor(data?: OutageListItem) {
        this.outageId = data?.outageId;
        this.location = data?.location;
        this.name = data?.name;
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.duration = data?.duration;
        this.isNew = data?.isNew;
        this.isUpdated = data?.isUpdated;
        this.cost = data?.cost;
        this.currency = data?.currency?.toString();
    }
}
