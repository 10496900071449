const aeroClassifications = {
    maintenance: 'Maintenance',
    risk: 'Risk',
    opportunity: 'Opportunity',
    statutory: 'Statutory',
};

const maintenanceTypes = {
    corrective: 'outageDetails.types.maintenanceTypes.corrective',
    mandatoryPreventive: 'outageDetails.types.maintenanceTypes.mandatoryPreventive',
    nonMandatoryPreventive: 'outageDetails.types.maintenanceTypes.nonMandatoryPreventive',
};

enum ActivityMode {
    EditActivity = 1,
    AddSubActivity = 2,
    EditSubActivity = 3,
}

export {
    aeroClassifications,
    maintenanceTypes,
    ActivityMode,
}
