import { sdk } from '@/utils/sdk';
import { ActivityUpdateGeneralInformationRequest, SubActivityUpdateRequest, SubActivityAddRequest } from '@/models';
import { serializePathParam } from '@/utils//helpers/api-helper';

class ActivityApi {
    public async getActivityGeneralInformation(id: number): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `activities/${serializePathParam(id)}`
        )).data
    }
    
    public async activityUpdateGeneralInformation(id: string, body?: ActivityUpdateGeneralInformationRequest): Promise<any> {
        return (await sdk?.backend.request.api.put<any>(
            `activities/${serializePathParam(id)}`,
            body
        )).data
    }
    
    public async addSubActivity(parentActivityId: string, body?: SubActivityAddRequest): Promise<any> {
        return (await sdk?.backend.request.api.post<any>(
            `activities/${serializePathParam(parentActivityId)}/sub-activities`,
            body
        )).data
    }
    
    public async updateSubActivity(parentActivityId: string, id: string, body?: SubActivityUpdateRequest): Promise<any> {
        return (await sdk?.backend.request.api.put<any>(
            `activities/${serializePathParam(parentActivityId)}/sub-activities/${serializePathParam(id)}`,
            body
        )).data
    }
    
    public async getActivitySubActivities(id: number): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `activities/${serializePathParam(id)}/subactivities`,
        )).data
    }
    
    public async deleteSubActivity(parentActivityId: number, id: number): Promise<any> {
        return (await sdk?.backend.request.api.delete<any>(
            `activities/${serializePathParam(parentActivityId)}/sub-activities/${serializePathParam(id)}`,
        )).data
    }
    
    public async syncActivities(outageId: string | null): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `activities/sync/${serializePathParam(outageId)}`,
        )).data
    }
}

export const activityApi = new ActivityApi();