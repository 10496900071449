import { generateQuery } from '@/utils/helpers/api-helper';
import { sdk } from '@/utils/sdk';

class RiskAssessmentApi {
    public async riskAssessmentDesccriptionTypes(): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            'risk-assessment/frequency-descriptions',
        )).data
    }
    
    public async riskAssessmentImpactTypes(): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            'risk-assessment/impact-types',
        )).data
    }
    
    public async costImpactCalculation(
        eASGlobalId: number | undefined,
        duration: number | undefined,
        capacityLoss: number | undefined,
        materialCost: number | undefined
    ): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `risk-assessment/calculations/cost-impact-score${generateQuery({
                eASGlobalId,
                duration,
                capacityLoss,
                materialCost,
            })}`,
        )).data
    }

    public async riskAssessmentRankingThresholds(technologyId?: number): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `risk-assessment/ranking-thresholds${generateQuery({technologyId})}`,
        )).data
    }
}

export const riskAssessmentApi = new RiskAssessmentApi();