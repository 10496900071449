import store from '@/store';
export class CostRiskAssessmentRow {
    events?: number;
    year?: number;
    description?: number;
    impact?: number;
    totalScore?: number;
    occuranceLikelihoodType?: number;
    cost?: number;
    duration?: number;
    capacityLoss?: number;
    materialCost?: number;

    public constructor(data?: any) {
        this.events = data?.events;
        this.year = data?.year;
        this.description = data?.description;
        this.impact = data?.impact;
        this.totalScore = data?.totalScore;
        this.occuranceLikelihoodType = data?.occuranceLikelihoodType ?? 1;
        this.cost = data?.cost;
        this.duration = data?.duration;
        this.capacityLoss = data?.capacityLoss || store.state?.OutageDetailsModule?.capacity;
        this.materialCost = data?.materialCost;
    }

    static mapCostRiskAssessmentRowModel(costRiskAssessmentRow?: CostRiskAssessmentRow): CostRiskAssessmentRow {
        return new CostRiskAssessmentRow({
            events: costRiskAssessmentRow?.events,
            year: costRiskAssessmentRow?.year,
            description: costRiskAssessmentRow?.description,
            impact: costRiskAssessmentRow?.impact,
            totalScore: costRiskAssessmentRow?.totalScore,
            occuranceLikelihoodType: costRiskAssessmentRow?.occuranceLikelihoodType,
            cost: costRiskAssessmentRow?.cost,
            duration: costRiskAssessmentRow?.duration,
            capacityLoss: costRiskAssessmentRow?.capacityLoss,
            materialCost: costRiskAssessmentRow?.materialCost,
        });
    }
}
