import { CommonRiskAssessmentRow } from './common-risk-assessment-row';

export class CommonRiskAssessment {
    activityRiskAssessmentComment?: string;
    riskAssessmentBefore?: CommonRiskAssessmentRow;
    riskAssessmentAfter?: CommonRiskAssessmentRow;

    public constructor(data?: any) {
        this.activityRiskAssessmentComment = data?.activityRiskAssessmentComment;
        this.riskAssessmentBefore = new CommonRiskAssessmentRow(data?.riskAssessmentBefore);
        this.riskAssessmentAfter = new CommonRiskAssessmentRow(data?.riskAssessmentAfter);
    }

    public static mapCommonRiskAssessmentModel(commonRiskAssessment?: CommonRiskAssessment): CommonRiskAssessment {
        return new CommonRiskAssessment({
            activityRiskAssessmentComment: commonRiskAssessment?.activityRiskAssessmentComment,
            riskAssessmentBefore: CommonRiskAssessmentRow.mapCommonRiskAssessmentRowModel(commonRiskAssessment?.riskAssessmentBefore),
            riskAssessmentAfter: CommonRiskAssessmentRow.mapCommonRiskAssessmentRowModel(commonRiskAssessment?.riskAssessmentAfter),
        });
    }
}
