import { User } from '../user';

export class GeneralInformation {
    outageId?: string;
    plantName?: string;
    plantId?: number;
    unitId?: number;
    unitName?: string;
    capacity?: number;
    globalId?: number;
    startDate?: Date;
    endDate?: Date;
    currency?: string;
    participants?: User[];

    public constructor(data?: any) {
        this.outageId = data?.outageId;
        this.plantName = data?.plantName;
        this.plantId = data?.plantId;
        this.unitId = data?.unitId;
        this.unitName = data?.unitName;
        this.capacity = data?.capacity;
        this.globalId = data?.globalId;
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.currency = data?.currency;
        this.participants = data?.participants ?? [];
    }
}
