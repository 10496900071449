import { Unit, FilterQueryParam } from '@/models';
import MessageHandler from '@/utils/message-handler';
import { ErrorResponse } from '@/models';
import { setIsLoading } from '@/utils/helpers/app-loading';
import PowerPlantHelper from '@/utils/helpers/power-plant-helper';
import { sdk } from '@/utils/sdk';
import { generateQuery } from '@/utils/helpers/api-helper';

class UnitService {

    private endpoint = 'units/module';

    public async getUnits(params: FilterQueryParam): Promise<Unit[]> {
        setIsLoading(true);
        try {
            const moduleId = process.env.VUE_APP_MODULE_ID;
            const powerPlantIds = PowerPlantHelper.addAllIrschingDuplicatesIfSelected(params?.powerPlantIds ?? []);
            return (await sdk?.core.masterData.request.api.
                get<any>(
                    `${this.endpoint}/${moduleId}${generateQuery({
                        fleetIds: params?.technologyIds,
                        plantIds: powerPlantIds
                    })}`,
                ))?.data?.result?.items;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }
}

export const unitService = new UnitService();
