const serializeArray = (parameterName: string, arr?: number[] | null): string => {
    let queryResult = '';
    if (arr) {
        queryResult += arr.filter(Boolean).map(entry => `${parameterName}=${entry}`).join('&')
    }
    return queryResult
}

const generateQuery = (obj: Record<string, number | string | boolean | null | undefined | number[] | string[]>): string => {
    let queryResult = '';
    for (const property in obj) {
        if (!!obj[property] || typeof obj[property] === 'boolean') {
            if (queryResult.length && queryResult.lastIndexOf('&') !== queryResult.length - 1) {
                queryResult = `${queryResult}&`
            }
            if (typeof obj[property] === 'object') {
                queryResult += serializeArray(property, obj[property] as any)
            } else {
                queryResult += `${property}=${obj[property]}`
            }
        }
    }
    return queryResult ? `?${queryResult}` : queryResult
}

const serializePathParam = (param?: string | number | null): string | number | null | undefined => {
    if (!param) return param
    return param.toString().replaceAll('/', '%2F')
}

export {
    generateQuery,
    serializePathParam,
};
