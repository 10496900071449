import { KeyValueModel } from '@/models';

export class RiskAssessmentImpact {
    impactSafety?: KeyValueModel[];
    impactRegulatory?: KeyValueModel[];
    impactReputation?: KeyValueModel[];
    impactEnvironment?: KeyValueModel[];

    public constructor(data?: RiskAssessmentImpact) {
        this.impactSafety = data?.impactSafety;
        this.impactRegulatory = data?.impactRegulatory;
        this.impactReputation = data?.impactReputation;
        this.impactEnvironment = data?.impactEnvironment;
    }
}
