export class User {
    id?: number;
    kid?: string;
    email?: string;
    name?: string;
    department?: string;

    public constructor(data?: any) {
        this.id = data?.id;
        this.kid = data?.kid;
        this.email = data?.email;
        this.name = data?.name;
        this.department = data?.department;
    }
}

export interface UserFilterModel {
    page?: number;
    size?: number;
    term?: string;
    sortColumn?: number;
    sortDirection?: number;
}
