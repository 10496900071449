export class EnvironmentRiskAssessmentRow {
    events?: number;
    year?: number;
    description?: number;
    impact?: number;
    totalScore?: number;
    occuranceLikelihoodType?: number;
    probability?: number;

    public constructor(data?: any) {
        this.events = data?.events;
        this.year = data?.year;
        this.description = data?.description;
        this.impact = data?.impact;
        this.totalScore = data?.totalScore;
        this.occuranceLikelihoodType = data?.occuranceLikelihoodType ?? 1;
        this.probability = data?.probability;
    }

    static mapEnvironmentRiskAssessmentRowModel(costRiskAssessmentRow?: EnvironmentRiskAssessmentRow): EnvironmentRiskAssessmentRow {
        return new EnvironmentRiskAssessmentRow({
            events: costRiskAssessmentRow?.events,
            year: costRiskAssessmentRow?.year,
            description: costRiskAssessmentRow?.description,
            impact: costRiskAssessmentRow?.impact,
            totalScore: costRiskAssessmentRow?.totalScore,
            occuranceLikelihoodType: costRiskAssessmentRow?.occuranceLikelihoodType,
            probability: costRiskAssessmentRow?.probability
        });
    }
}
