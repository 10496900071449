import { PowerPlant } from '@/models';

export default class PowerPlantHelper {
    private static firstIrschingPlantId = 8006;
    private static secondIrschingPlantId = 8065;
    private static thirdIrschingPlantId = 26294;

    public static removeIrschingDuplicatesIfExists(powerPlants: PowerPlant[]): PowerPlant[] {
        return powerPlants.filter((powerPlant: PowerPlant) =>
            powerPlant?.id !== this.secondIrschingPlantId && powerPlant?.id !== this.thirdIrschingPlantId);
    }

    public static addAllIrschingDuplicatesIfSelected([...powerPlants]: number[]): number[] {
        if (powerPlants.includes(this.firstIrschingPlantId)) {
            powerPlants.push(this.secondIrschingPlantId);
            powerPlants.push(this.thirdIrschingPlantId);
        }
        return powerPlants;
    }
}
