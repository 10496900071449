import { GeneralInformation } from './general-information';
import { OutageFile } from './outage-file';
import { Budget } from './budget';
import { RiskOfOverrun } from './risk-of-overrun';
import { OutageGetResponse } from './rbs-budget';

export class OutageDetail {

    generalInformation?: GeneralInformation;
    budget?: Budget;
    files?: OutageFile[];
    riskOfOverrun?: RiskOfOverrun;
    technologyId?: number;
    currencyRate?: number;
    hideRbsFunctionality?: boolean;

    public constructor(data?: OutageGetResponse) {
        this.generalInformation = new GeneralInformation(data);
        this.budget = new Budget(data);
        this.files = data?.files?.map((file: OutageFile) => new OutageFile(file));
        this.riskOfOverrun = new RiskOfOverrun(data?.riskOfOverrun);
        this.technologyId = data?.technologyId;
        this.currencyRate = data?.currencyRate;
        this.hideRbsFunctionality = data?.hideRbsFunctionality;
    }
}
