export class SafetyRiskAssessmentRow {
    events?: number;
    year?: number;
    description?: number;
    impact?: number;
    totalScore?: number;
    occuranceLikelihoodType?: number;
    people?: number;
    probability?: number;

    public constructor(data?: SafetyRiskAssessmentRow) {
        this.events = data?.events;
        this.year = data?.year;
        this.description = data?.description;
        this.impact = data?.impact;
        this.totalScore= data?.totalScore;
        this.occuranceLikelihoodType= data?.occuranceLikelihoodType ?? 1;
        this.people= data?.people;
        this.probability= data?.probability;
    }

    static mapSafetyRiskAssessmentRowModel(safetyRiskAssessmentRow?: SafetyRiskAssessmentRow): SafetyRiskAssessmentRow {
        return new SafetyRiskAssessmentRow({
            events: safetyRiskAssessmentRow?.events,
            year: safetyRiskAssessmentRow?.year,
            description: safetyRiskAssessmentRow?.description,
            impact: safetyRiskAssessmentRow?.impact,
            totalScore: safetyRiskAssessmentRow?.totalScore,
            occuranceLikelihoodType: safetyRiskAssessmentRow?.occuranceLikelihoodType,
            people: safetyRiskAssessmentRow?.people,
            probability: safetyRiskAssessmentRow?.probability
        });
    }
}
