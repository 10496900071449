import {
    OutageListItem, ErrorResponse, OutageFilterParams, OutageFile, OutageBudgetAndRisk, RiskOverviewActivity,
    OutageDetail, ParticipantAddRequest, OutageGlobalIdManageRequest, OutageActivityListItem,
    OutageRankedActivityListItem, 
} from '@/models';
import MessageHandler from '@/utils/message-handler';
import { setIsLoading } from '@/utils/helpers/app-loading';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import { PrioritizationLog } from '@/models/prioritization-log';
import Vue from 'vue';
import { outageApi } from '@/services';

export class OutageService {
    public async getOutageList(filterParams: OutageFilterParams): Promise<OutageListItem[]> {
        setIsLoading(true);
        try {
            const outages = (await outageApi.listOutages(
                filterParams?.technologyIds,
                filterParams?.plantIds,
                filterParams?.unitIds,
                filterParams?.year,
                false,
                !filterParams?.searchTerm ? undefined : filterParams?.searchTerm,
                undefined,
            ))?.result?.items;
            return outages?.map((item: OutageListItem) => new OutageListItem(item)) ?? [];
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }

    public async getOutageDetailsById(id: any): Promise<OutageDetail> {
        try {
            setIsLoading(true);
            const details = (await outageApi.getOutage(id))?.result;
            return new OutageDetail(details);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new OutageDetail();
        } finally {
            setIsLoading(false);
        }
    }

    public async listOutagesActivities(outageId: string): Promise<any> {
        try {
            setIsLoading(true);
            const scopeActivities = (await outageApi.listOutagesActivities(
                outageId
            ))?.result?.items;
            return scopeActivities?.map((item: OutageActivityListItem) => new OutageActivityListItem(item)) ?? [];
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }

    public async listOutagesRankedActivities(outageId: string, executionType: number): Promise<any> {
        try {
            setIsLoading(true);
            const rankedActivities = (await outageApi.listOutagesRankedActivities(
                outageId,
                executionType
            ))?.result?.data;
            return rankedActivities?.map((item: OutageRankedActivityListItem) => new OutageRankedActivityListItem(item)) ?? [];
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }

    public async saveGlobalId(outageId = '', globalId: number | undefined): Promise<boolean> {
        try {
            setIsLoading(true);
            await outageApi.manageGlobalId(outageId, new OutageGlobalIdManageRequest({
                outageId,
                globalId
            }));
            MessageHandler.showToastMessage(
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.generalInformation.successfullySaved') as string, ToastMessageTypes.SUCCESS)
            return true;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return false;
        } finally {
            setIsLoading(false);
        }
    }

    public async saveParticipants(outageId: string, participants: string[]): Promise<boolean> {
        try {
            setIsLoading(true);
            await outageApi.manageParticipants(outageId, new ParticipantAddRequest({
                outageId,
                participants
            }));
            MessageHandler.showToastMessage(
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.generalInformation.participantsSuccessfullySaved') as string, ToastMessageTypes.SUCCESS);
            return true;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return false;
        } finally {
            setIsLoading(false);
        }
    }

    public async prioritizeOutagesActivities(outageId: string): Promise<boolean> {
        try {
            setIsLoading(true);
            await outageApi.outagesActivityPrioritize(outageId);
            MessageHandler.showToastMessage(
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.table.successfullyPrioritized') as string, ToastMessageTypes.SUCCESS);
            return true;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return false;
        } finally {
            setIsLoading(false);
        }
    }

    public async getLastPrioritizedBy(outageId: string): Promise<PrioritizationLog> {
        try {
            setIsLoading(true);
            const prioritizationLog = (await outageApi.outagesActivityPrioritizeLog(outageId)).result;
            return new PrioritizationLog(prioritizationLog);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new PrioritizationLog();
        } finally {
            setIsLoading(false);
        }
    }

    public async generateRbs(outageId: string): Promise<boolean> {
        try {
            setIsLoading(true);
            await outageApi.generateDocument(outageId);
            MessageHandler.showToastMessage(
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.table.pleaseCheckTheFileSectionToViewIt') as string,
                ToastMessageTypes.SUCCESS,
                undefined,
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.table.rbsSuccesfullyCreated') as string,
            );
            return true;
        }
        catch (error) {
            const message = (error as any).errors[0];
            MessageHandler.showToastMessage(
                message,
                ToastMessageTypes.INFO,
                undefined,
                Vue.prototype?.$translationManager?.vueI18n.t('outageDetails.table.couldNotGenerateRbsDocument') as string,
            );
            return false;
        } finally {
            setIsLoading(false);
        }
    }

    public async getOutageDocuments(outageId: string): Promise<any> {
        try {
            setIsLoading(true);
            const files = (await outageApi.getOutageDocuments(outageId)).result?.data;
            return files?.map((item: OutageFile) => new OutageFile(item)) ?? [];
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }

    public async getRiskOverviewActivities(outageId: string, riskAssessmentType?: number): Promise<any> {
        try {
            setIsLoading(true);
            const riskOverviewActivities = (await outageApi.getRiskOverview(outageId, riskAssessmentType, undefined, undefined)).result?.data;
            return riskOverviewActivities?.map((riskOverviewActivity: any) => new RiskOverviewActivity(riskOverviewActivity))
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }

    public async getBudgetsAndRiskOfOverrun(outageId: string): Promise<any> {
        try {
            setIsLoading(true);
            const response = (await outageApi.getOutageBudgetsAndOverrunRisk(outageId)).result;
            return new OutageBudgetAndRisk(response);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new OutageBudgetAndRisk();
        } finally {
            setIsLoading(false);
        }
    }

    public async exportOutageDocument(outageId: string, downloadUrl: string, documentName: string): Promise<any> {
        try {
            setIsLoading(true);
            return (await outageApi.exportExcel(outageId, downloadUrl, documentName));
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return undefined;
        } finally {
            setIsLoading(false);
        }
    }
}

export const outageService = new OutageService();
