import Vue from 'vue';
import Vuex from 'vuex';
import AppModule from './app-module';
import UserModule from './user-module';
import HomeModule from './home-module';
import OutageDetailsModule from './outage-details-module';
import ActivityModule from './activity-module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        AppModule,
        UserModule,
        HomeModule,
        OutageDetailsModule,
        ActivityModule,
    }
});
