import { KeyValueModel } from '@/models';

export class RiskAssessmentDescription {
    descriptionCost?: KeyValueModel[];
    descriptionSafety?: KeyValueModel[];
    descriptionRegulatory?: KeyValueModel[];
    descriptionReputation?: KeyValueModel[];
    descriptionEnvironment?: KeyValueModel[];

    public constructor(data?: RiskAssessmentDescription) {
        this.descriptionCost = data?.descriptionCost;
        this.descriptionSafety = data?.descriptionSafety;
        this.descriptionRegulatory = data?.descriptionRegulatory;
        this.descriptionReputation = data?.descriptionReputation;
        this.descriptionEnvironment = data?.descriptionEnvironment;
    }
}
