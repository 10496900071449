import { OverrunRisk } from './overrun-risk';

export class RiskOfOverrun {
    overrunLikelihood?: number;
    variationDescription?: string;
    influenceOnOutage: OverrunRisk;
    costToResolve: OverrunRisk;

    public constructor(overrunLikelihood?: number, variationDescription?: string, influenceOnOutage?: OverrunRisk | OverrunRisk, costToResolve?: OverrunRisk | OverrunRisk) {
        this.overrunLikelihood = overrunLikelihood;
        this.variationDescription = variationDescription;
        this.influenceOnOutage = new OverrunRisk(influenceOnOutage);
        this.costToResolve = new OverrunRisk(costToResolve);
    }
}
