export class ActivityDurationOverrunRisk {
    highestDurationOverrunActivityName?: string;
    highestDurationOverrunDays?: number;
    overrunLikelihood?: number;

    public constructor(data?: ActivityDurationOverrunRisk) {
        this.highestDurationOverrunActivityName = data?.highestDurationOverrunActivityName;
        this.highestDurationOverrunDays = data?.highestDurationOverrunDays;
        this.overrunLikelihood = data?.overrunLikelihood;
    }
}
