import { SafetyRiskAssessmentRow } from './safety-risk-assessment-row';
export class SafetyRiskAssessment {
    activityRiskAssessmentComment?: string;
    riskAssessmentBefore?: SafetyRiskAssessmentRow;
    riskAssessmentAfter?: SafetyRiskAssessmentRow;

    public constructor(data?: any) {
        this.activityRiskAssessmentComment = data?.activityRiskAssessmentComment;
        this.riskAssessmentBefore = new SafetyRiskAssessmentRow(data?.riskAssessmentBefore);
        this.riskAssessmentAfter = new SafetyRiskAssessmentRow(data?.riskAssessmentAfter);
    }

    public static mapSafetyRiskAssessmentModel(safetyRiskAssessment?: SafetyRiskAssessment): SafetyRiskAssessment {
        return new SafetyRiskAssessment({
            activityRiskAssessmentComment: safetyRiskAssessment?.activityRiskAssessmentComment,
            riskAssessmentBefore: SafetyRiskAssessmentRow.mapSafetyRiskAssessmentRowModel(safetyRiskAssessment?.riskAssessmentBefore),
            riskAssessmentAfter: SafetyRiskAssessmentRow.mapSafetyRiskAssessmentRowModel(safetyRiskAssessment?.riskAssessmentAfter),
        });
    }
}
