import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { routeNames } from '@/router/route-names';

const lazyLoad = (view: string): any => {
    return(): any => import(`@/views/${view}.vue`)
}

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: routeNames.HOME,
        component: lazyLoad('home'),
    },
    {
        path: '/outage-details/:id',
        name: routeNames.OUTAGE_DETAILS,
        component: lazyLoad('outage-details'),
    },
    {
        path: '*',
        component: lazyLoad('page-not-found'),
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
