export const routeNames = {
    HOME: 'Home',
    OUTAGE_DETAILS: 'OutageDetails',
}

export const viewsNames = {
    SCOPE_LIST: 'scope-list',
    RANKED_SCOPE: 'ranked-scope',
    RISK_OVERVIEW: 'risk-overview'
}
