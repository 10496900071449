<template>
    <div>
        <adam-header-bar
            :username="userName"
            :emailaddress="userEmail"
            :show-notifications.prop="false"
            :class="[
                { 'bg-dev': isDevelopmentEnv },
                { 'bg-uat': isUatEnv },
            ]"
            @apps-click="clickDrawer"
        >
            <span
                slot="headerContent"
                class="app-logo"
            >
                <a href="/">
                    <img
                        class="app-logo-img"
                        src="~adam.ui-core/assets/brand/logo-coode-white.svg"
                    >
                </a>
            </span>
            <span slot="headerContent">
                <h4>{{ $t('appTitle') }}</h4>
            </span>
            <div slot="profileDetails">
                <user-profile />
            </div>
        </adam-header-bar>
        <app-drawer
            ref="appDrawer"
            :data.prop="applications"
            :labels.prop="getAppDrawerLabels"
            :on-click.prop="handleAppClick"
        >
            <span
                slot="appLogo"
                class="app-logo"
            >
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg">
            </span>
        </app-drawer>
        <snackbar />
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import UserProfile from './user-profile/user-profile.vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { AppService } from '@/services';
import { Application } from '@/models';
import Snackbar from '@/components/snackbar/snackbar.vue';
import { EventBus } from '@/utils';
import { namespace } from 'vuex-class';

const appModule = namespace('AppModule');
const userModule = namespace('UserModule');

@Component({
    name: 'header-wrapper',
    components: {
        userProfile: UserProfile,
        snackbar: Snackbar,
    },
})
export default class HeaderWrapper extends Vue {
    @appModule.Action
    public setIsLoading!: (isLoading: boolean) => void

    @userModule.Action
    private loadUserProfile!: () => Promise<void>

    @userModule.Getter
    private userName!: () => string | undefined;

    @userModule.Getter
    private userEmail!: () => string | undefined;

    private appService: AppService = new AppService();
    private applications: MenuEntry[] = [];

    private async mounted(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        await this.loadUserProfile();
        await this.loadApps();
    }

    private async loadApps(): Promise<void> {
        this.setIsLoading(true);
        try {
            const apps = (await this.appService.getApps()).result.items;
            this.applications = this.processApps(apps);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        } finally {
            this.setIsLoading(false);
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconUri: this.getLogo(app.logoUrl),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a?.label?.toLowerCase();
            const nameB = b?.label?.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private getLogo(url: string | undefined): string {
        if (url) return url;
        return 'app-generic';
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$t('appsWithAccess'), miscellaneous: this.$t('appsWithoutAccess') };
    }

    private get isDevelopmentEnv(): boolean {
        return ['dev', 'development'].includes(process.env.VUE_APP_ENVIRONMENT as string);
    }

    private get isUatEnv(): boolean {
        return process.env.VUE_APP_ENVIRONMENT === 'uat';
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.app-logo {
    img {
        height: 90%;
        margin-right: 1rem;
        padding: 6px 6px 3px 6px;
    }

    .app-logo-img{
        height: 4.5rem;
    }
}
.logout-button {
    height: 3.5rem;
    width: 100%;
    color: @dark-grey;
    background: @light-grey;
    transition: 0.2s ease-in;
    border-radius: 0;
    &:hover {
        transition: 0.2s ease-in;
        background: #E0E0E0;
    }
}

::v-deep .adam-header__separator.adam-header-bar {
    background: white;
}

::v-deep .bg-dev {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-dev;

        .adam-header-user__icon.adam-header-bar path {
            fill: @uniper-dev;
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-dev-darker;
        }

        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-dev-darker;
        }
    }
}

::v-deep .bg-uat {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-uat;

        .adam-header-user__icon.adam-header-bar path {
            fill:@uniper-uat;
        }

        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-uat;
        }

        .adam-header-user__icon.adam-header-bar:hover {
            background: @uniper-uat-darker;
        }

        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-uat-darker;
        }
    }
}
</style>
