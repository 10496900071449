export class OutageActivityListItem {
    id?: number;
    activityId?: string;
    activityTitle?: string;
    activityType?: number;
    projectGlobalId?: string;
    projectName?: string;
    riskMaintenanceId?: string;
    aeroClassificationName?: any;
    cost?: number;
    mirr?: number;
    costBefore?: number;
    costAfter?: number;
    safetyBefore?: number;
    safetyAfter?: number;
    environmentBefore?: number;
    environmentAfter?: number;
    regulatoryBefore?: number;
    regulatoryAfter?: number;
    isDifferentBudget?: boolean;
    reputationalBefore?: number;
    reputationalAfter?: number;
    parentActivityId?: number;


    public constructor(data?: OutageActivityListItem) {
        this.id = data?.id;
        this.activityId = data?.activityId;
        this.activityTitle = data?.activityTitle;
        this.activityType = data?.activityType;
        this.projectGlobalId = data?.projectGlobalId;
        this.projectName = data?.projectName;
        this.riskMaintenanceId = data?.riskMaintenanceId;
        this.aeroClassificationName = data?.aeroClassificationName;
        this.cost = data?.cost;
        this.mirr = data?.mirr;
        this.costBefore = data?.costBefore;
        this.costAfter = data?.costAfter;
        this.safetyBefore = data?.safetyBefore;
        this.safetyAfter = data?.safetyAfter;
        this.environmentBefore = data?.environmentBefore;
        this.environmentAfter = data?.environmentAfter;
        this.regulatoryBefore = data?.regulatoryBefore;
        this.regulatoryAfter = data?.regulatoryAfter;
        this.isDifferentBudget = data?.isDifferentBudget;
        this.reputationalBefore =  data?.reputationalBefore;
        this.reputationalAfter = data?.reputationalAfter;
        this.parentActivityId = data?.parentActivityId;
    }
}
