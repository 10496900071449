<template>
    <div class="flex-col profile-container">
        <div class="profile-details">
            <div class="pb-1">
                <button
                    type="button"
                    @click="copyToken()"
                    class="copy-token-button left-align"
                >
                    {{ $t('copyToken') }}
                    <font-awesome-icon icon="copy" />
                </button>
            </div>
            <div class="pb-1">
                <a
                    :href="swaggerURL"
                    target="_blank"
                    class="swagger-link"
                >
                    {{ $t('openSwagger') }}
                </a>
            </div>
            <div class="flex-col flex-3 pb-1">
                <div class="version">
                    {{ $t('version') }}&nbsp;{{ version }}
                </div>
                <div class="version ">
                    {{ $t('apiUrl') }}&nbsp;
                    <a
                        :href="apiUrl"
                        class="swagger-link"
                    >
                        {{ apiUrl }}
                    </a>
                </div>
            </div>
            <pui-button
                v-if="shouldShowFeedbackButton"
                state="secondary"
                icon="report"
                class="profile-action-button"
                @click="sendToFeedback()"
            >
                {{ $t('sendFeedback') }}
            </pui-button>
            <pui-button
                v-if="shouldShowFeedbackButton"
                state="secondary"
                icon="warning"
                class="profile-action-button profile-action-button--important"
                @click="sendToIncident()"
            >
                {{ $t('reportIncident') }}
            </pui-button>
            <pui-button
                icon="power"
                class="profile-action-button"
                @click="logOut()"
            >
                {{ $t('signOut') }}
            </pui-button>
        </div>
        <input
            type="text"
            class="copy-text"
            id="copyText"
        >
    </div>
</template>

<script lang="ts">

import {Component, Vue} from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import clickOutside from '@/directives/click-outside';
import { namespace } from 'vuex-class';
import { sdk } from '@/utils/sdk';

const userModule = namespace('UserModule');

@Component({
    directives: {
        clickOutside,
    },
})
export default class UserProfile extends Vue {
    private version?: string = process.env.VUE_APP_VERSION;
    private apiUrl?: string = process.env.VUE_APP_API_BASE_URL;
    private swaggerURL?: string = process.env.VUE_APP_API_SWAGGER_URL;
    private appStoreUrl?: string = process.env.VUE_APP_APP_STORE_UI_URL;
    private useCaseId?: string = process.env.VUE_APP_USE_CASE_ID;
    private environment?: string = process.env.VUE_APP_ENVIRONMENT;
    private rbsScope: string = process.env.VUE_APP_RBS_SCOPE as string;
    private detailsToggled = false;

    private get shouldShowFeedbackButton(): boolean {
        return !!this.appStoreUrl && !!this.useCaseId;
    }

    private sendToFeedback(): void {
        if (!this.appStoreUrl || !this.useCaseId) {
            return;
        }

        window.open(`${this.appStoreUrl}/contact-page/${this.useCaseId}?isFeedback=true`, '_blank');
    }

    private sendToIncident(): void {
        if (!this.appStoreUrl || !this.useCaseId) {
            return;
        }

        window.open(`${this.appStoreUrl}/incident-page/${this.useCaseId}`, '_blank');
    }

    private logOut(): void {
        sdk?.auth.logout();
    }

    private async copyToken(): Promise<void> {
        const textElement: any = document.getElementById('copyText');
        if (textElement) {
            const token = await sdk?.auth.getToken(this.rbsScope);
            textElement.value = '';
            textElement.value = `Bearer ${token}`;
            textElement.select();
            document.execCommand('copy');
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToClipboard');
        } else {
            throw new Error('Copy text element is undefined');
        }
    }

    private offFocus(): void {
        if (this.detailsToggled) {
            this.detailsToggled = false;
        }
    }
}
</script>

<style scoped lang="less">
@import '../../../variables.less';
.profile-details {
    color: @dark-blue-grey;
    z-index: 10;
    font-size: 1.6rem;
    right: 0;
    .profile-action-button {
        text-transform: none;
        width: 100%;
        border-radius: 0;
        margin-bottom: 2px;
        height: 3.6rem;

        &--important {
            border: 1px solid @contextual-red !important;
            color: @contextual-red !important;
            &:hover {
                color: @dark-red !important;
                border: 1px solid @dark-red !important;
            }
        }
    }
    .copy-token-button {
        width: 100%;
        color: @dark-blue-grey;
        transition: 0.2s ease-in;
        box-shadow: none;
        padding: 0;
        :hover {
            transition: 0.2s ease-in;
        }
    }
    .swagger-link {
        color: @uniper-blue;
    }
}
.version {
    font-size: 1.3rem;
    margin: 0.3rem;
}
.copy-text {
    position: fixed;
    top: -99999rem;
}
</style>
