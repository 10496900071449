import { EnvironmentRiskAssessmentRow } from './environment-risk-assessment-row';

export class EnvironmentRiskAssessment {
    activityRiskAssessmentComment?: string;
    riskAssessmentBefore?: EnvironmentRiskAssessmentRow;
    riskAssessmentAfter?: EnvironmentRiskAssessmentRow;

    public constructor(data?: any) {
        this.activityRiskAssessmentComment = data?.activityRiskAssessmentComment;
        this.riskAssessmentBefore = new EnvironmentRiskAssessmentRow(data?.riskAssessmentBefore);
        this.riskAssessmentAfter = new EnvironmentRiskAssessmentRow(data?.riskAssessmentAfter);
    }

    public static mapEnvironmentRiskAssessmentModel(environmentRiskAssessment?: EnvironmentRiskAssessment): EnvironmentRiskAssessment {
        return new EnvironmentRiskAssessment({
            activityRiskAssessmentComment: environmentRiskAssessment?.activityRiskAssessmentComment,
            riskAssessmentBefore: EnvironmentRiskAssessmentRow.mapEnvironmentRiskAssessmentRowModel(environmentRiskAssessment?.riskAssessmentBefore),
            riskAssessmentAfter: EnvironmentRiskAssessmentRow.mapEnvironmentRiskAssessmentRowModel(environmentRiskAssessment?.riskAssessmentAfter),
        });
    }
}
