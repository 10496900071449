import { RiskAssesmentType, RankExecutionType, ParticipantAddRequest, OutageGlobalIdManageRequest } from '@/models';
import { sdk } from '@/utils/sdk';
import { serializePathParam, generateQuery } from '@/utils/helpers/api-helper';

class OutageApi {
    public async listOutages(
        technologyIds: number[] | null | undefined,
        plantIds: number[] | null | undefined,
        unitIds: number[] | null | undefined,
        year: number | undefined,
        onlyMajorOutages: boolean | undefined,
        term: string | null | undefined,
        termToLower: string | null | undefined
    ): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `outages${generateQuery({
                technologyIds,
                plantIds,
                unitIds,
                year,
                onlyMajorOutages,
                term,
                termToLower,
            })}`,
        )).data;
    }

    public async getOutage(id: string | null): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `outages/${serializePathParam(id)}`,
        )).data
    }

    public async listOutagesActivities(outageId: string | null): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `outages/activities/${serializePathParam(outageId)}`,
        )).data
    }
    
    public async listOutagesRankedActivities(outageId: string | null, executionType?: RankExecutionType): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `outages/ranked-activities/${serializePathParam(outageId)}${generateQuery({executionType})}`,
        )).data
    }

    public async manageGlobalId(outageId: string, body?: OutageGlobalIdManageRequest): Promise<any> {
        return (await sdk?.backend.request.api.post<any>(
            `outages/global-id/${serializePathParam(outageId)}`,
            body
        )).data
    }

    public async manageParticipants(outageId: string, body?: ParticipantAddRequest): Promise<any> {
        return (await sdk?.backend.request.api.post<any>(
            `outages/participants/manage/${serializePathParam(outageId)}`,
            body
        )).data
    }
    
    public async outagesActivityPrioritize(outageId: string | null): Promise<any> {
        return (await sdk?.backend.request.api.post<any>(
            `outages/prioritize/${serializePathParam(outageId)}`,
        )).data
    }
    
    public async outagesActivityPrioritizeLog(outageId: string | null): Promise<any> {
        return (await sdk?.backend.request.api.post<any>(
            `outages/prioritize/log/${serializePathParam(outageId)}`,
        )).data
    }
    
    public async generateDocument(outageId: string | null): Promise<any> {
        return (await sdk?.backend.request.api.post<any>(
            `outages/documents/generate-document/${serializePathParam(outageId)}`,
        )).data
    }
    
    public async getOutageDocuments(outageId: string | null): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `outages/documents/${serializePathParam(outageId)}`,
        )).data
    }

    public async getRiskOverview(
        outageId: string | null,
        riskType: RiskAssesmentType | undefined,
        term: string | null | undefined,
        termToLower: string | null | undefined
    ): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `outages/risk-overview/${serializePathParam(outageId)}${generateQuery({
                outageId,
                riskType,
                term,
                termToLower,
            })}`,
        )).data
    }

    public async getOutageBudgetsAndOverrunRisk(outageId: string | null): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `outages/budgets-and-risk-of-overrun/${serializePathParam(outageId)}`,
        )).data
    }
    
    public async exportExcel(outageId: string | null, downloadUrl?: string | null, documentName?: string | null): Promise<any> {
        return (await sdk?.backend.request.api.get<any>(
            `outages/download/${serializePathParam(outageId)}${generateQuery({
                downloadUrl,
                documentName,
            })}`,
            {
                headers: {
                    Accept: 'application/octet-stream',
                },
                responseType: 'blob',
            }
        )).data
    }
}

export const outageApi = new OutageApi();