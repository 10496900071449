export class CommonRiskAssessmentRow {
    events?: number;
    year?: number;
    description?: number;
    impact?: number;
    totalScore?: number;
    occuranceLikelihoodType?: number;

    public constructor(data?: any) {
        this.events = data?.events;
        this.year = data?.year;
        this.description = data?.description;
        this.impact = data?.impact;
        this.totalScore = data?.totalScore;
        this.occuranceLikelihoodType = data?.occuranceLikelihoodType ?? 1;
    }

    static mapCommonRiskAssessmentRowModel(commonRiskAssessmentRow?: CommonRiskAssessmentRow): CommonRiskAssessmentRow {
        return new CommonRiskAssessmentRow({
            events: commonRiskAssessmentRow?.events,
            year: commonRiskAssessmentRow?.year,
            description: commonRiskAssessmentRow?.description,
            impact: commonRiskAssessmentRow?.impact,
            totalScore: commonRiskAssessmentRow?.totalScore,
            occuranceLikelihoodType: commonRiskAssessmentRow?.occuranceLikelihoodType,
        });
    }
}
