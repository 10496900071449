export class OverrunRisk {
    low?: number;
    likely?: number;
    high?: number;

    public constructor(data?: any) {
        this.low = data?.low;
        this.likely = data?.likely;
        this.high = data?.high;
    }

    static mapOverrunRiskModel(overrunRisk: OverrunRisk): OverrunRisk {
        return new OverrunRisk({
            low: overrunRisk?.low,
            likely: overrunRisk?.likely,
            high: overrunRisk?.high,
        });
    }
}
