import { Technology } from '@/models';
import MessageHandler from '@/utils/message-handler';
import { ErrorResponse } from '@/models';
import { setIsLoading } from '@/utils/helpers/app-loading';
import { sdk } from '@/utils/sdk';

class TechnologyService {
    private endpoint = 'fleets/module';

    public async getTechonologies(): Promise<Technology[]> {
        setIsLoading(true);
        try {
            const moduleId = process.env.VUE_APP_MODULE_ID;
            return (await sdk?.core.masterData.request.api
                .get<any>(`${this.endpoint}/${moduleId}`))?.data?.result?.items;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }
}

export const technologyService = new TechnologyService();
