export class UserProfile {
    public name?: string;
    public email?: string;
    public permissions?: string[];

    public constructor(name?: string, email?: string, permissions?: string[]) {
        this.name = name;
        this.email = email;
        this.permissions = permissions?.map((permissionName: string) => permissionName);
    }
}
