export class RiskOverviewActivity {
    id?: number;
    cost?: number;
    activityType?: number;
    activityTitle?: string;
    totalScoreBefore?: number;
    totalScoreAfter?: number;
    impactScoreAfter?: number;
    impactScoreBefore?: number;
    likelihoodScoreAfter?: number;
    likelihoodScoreBefore?: number;

    constructor(data?: RiskOverviewActivity) {
        this.id = data?.id;
        this.activityType = data?.activityType;
        this.activityTitle = data?.activityTitle;
        this.cost = data?.cost;
        this.totalScoreBefore = data?.totalScoreBefore;
        this.totalScoreAfter = data?.totalScoreAfter;
        this.impactScoreBefore = data?.impactScoreBefore;
        this.impactScoreAfter = data?.impactScoreAfter;
        this.likelihoodScoreBefore = data?.likelihoodScoreBefore;
        this.likelihoodScoreAfter = data?.likelihoodScoreAfter;
    }
}
