import { SubSystem } from '@/models';
import MessageHandler from '@/utils/message-handler';
import { ErrorResponse } from '@/models';
import { setIsLoading } from '@/utils/helpers/app-loading';
import { sdk } from '@/utils/sdk';

class SystemService {
    private endpoint = 'sub-systems';

    public async getSubSystems(unitId?: number): Promise<SubSystem[]> {
        setIsLoading(true);
        try {
            const getUnitIdParam = (unitId?: number): string => unitId ? `unitId=${unitId}` : '';

            return (await sdk?.core.masterData.request.api
                .get<any>(`${this.endpoint}?${getUnitIdParam(unitId)}`))?.data?.result?.items;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }


}

export const systemService = new SystemService();
