import { OverrunRisk } from './overrun-risk';
import { RiskOfOverrun } from './risk-of-overrun';
import { CommonRiskAssessment } from './common-risk-assessment';
import { EnvironmentRiskAssessment } from './environment-risk-assessment';
import { CostRiskAssessment, SafetyRiskAssessment } from '@/models'
import store from '@/store';

export class AddEditActivity {
    id?: number;
    activityId?: string;
    parentActivityId?: number;
    parentActivityName?: string; // FE helper field
    msptActivityId?: number;
    mitigatedIrr?: number;
    activityTitle?: string;
    activityType?: number;
    outageId?: string;
    plantName?: string;
    currencyRate?: number;
    // General Information
    projectGlobalId?: string;
    projectName?: string;
    maintenanceType?: number;
    riskMaintenanceId?: string;
    description?: string;
    lastTimeExecuted?: Date;
    commentOnLastExecution?: string;
    aeroClassification?: number;
    plantArea?: string;
    procurementCategory?: string;
    mainSubSystemId?: string;
    mainSubSystemIndex?: number; // FE helper field
    // Financial & Budget
    estimatedGroupLifeStrategy?: number;
    planningControllingType?: number;
    oidPlannedBudget?: number;
    rbsPlannedBudget?: number;
    pmlPlannedBudget?: number;
    budgetTwoYearsPrior?: number;
    budgetPreviousYear?: number;
    budgetCurrentYear?: number;
    budgetNextYear?: number;
    isDifferentBudget?: boolean; // helper field
    // Activity Timing
    isOutsideOfOutage?: boolean;
    hasAdviceOnExecution?: boolean;
    activityTimingComment?: string;
    // Risk Of Overrun
    riskOfOverrun: RiskOfOverrun;
    // Risk Assessment
    costRiskAssessment?: CostRiskAssessment;
    safetyRiskAssessment?: SafetyRiskAssessment;
    regulatoryRiskAssessment?: CommonRiskAssessment;
    reputationRiskAssessment?: CommonRiskAssessment;
    environmentRiskAssessment?: EnvironmentRiskAssessment;

    public constructor(data?: any | AddEditActivity) {
        this.id = data?.id;
        this.activityId = data?.activityId;
        this.parentActivityId = data?.parentActivityId;
        this.parentActivityName = data?.parentActivityName,
        this.msptActivityId = data?.msptActivityId;
        this.mitigatedIrr = data?.mitigatedIrr;
        this.activityTitle = data?.activityTitle;
        this.activityType = data?.activityType;
        this.outageId = data?.outageId;
        this.plantName = data?.plantName || store.state?.OutageDetailsModule?.plantName;
        this.currencyRate = data?.currencyRate || store.state?.OutageDetailsModule?.currencyRate;

        this.projectGlobalId = data?.projectGlobalId;
        this.projectName = data?.projectName;
        this.maintenanceType = data?.maintenanceType;
        this.riskMaintenanceId = data?.riskMaintenanceId;
        this.description = data?.description;
        this.lastTimeExecuted = data?.lastTimeExecuted;
        this.commentOnLastExecution = data?.commentOnLastExecution;
        this.aeroClassification = data?.aeroClassification;
        this.plantArea = data?.plantArea;
        this.procurementCategory = data?.procurementCategory;
        this.mainSubSystemId = data?.mainSubSystemId;
        this.mainSubSystemIndex = undefined;

        this.estimatedGroupLifeStrategy = data?.estimatedGroupLifeStrategy;
        this.planningControllingType = data?.planningControllingType;
        this.oidPlannedBudget = data?.oidPlannedBudget;
        this.rbsPlannedBudget = data?.rbsPlannedBudget;
        this.pmlPlannedBudget = data?.pmlPlannedBudget;
        this.budgetTwoYearsPrior = data?.budgetTwoYearsPrior;
        this.budgetPreviousYear = data?.budgetPreviousYear;
        this.budgetCurrentYear = data?.budgetCurrentYear;
        this.budgetNextYear = data?.budgetNextYear;
        this.isDifferentBudget = data?.isDifferentBudget;

        this.isOutsideOfOutage = data?.isOutsideOfOutage ?? false;
        this.hasAdviceOnExecution = data?.hasAdviceOnExecution ?? true;
        this.activityTimingComment = data?.activityTimingComment;

        this.riskOfOverrun = new RiskOfOverrun(
            data?.overrunLikelihood,
            data?.variationDescription,
            data?.influenceOnOutage,
            data?.costToResolve
        );

        this.costRiskAssessment = new CostRiskAssessment(data?.costRiskAssessment);
        this.safetyRiskAssessment = new SafetyRiskAssessment(data?.safetyRiskAssessment);
        this.regulatoryRiskAssessment = new CommonRiskAssessment(data?.regulatoryRiskAssessment);
        this.reputationRiskAssessment = new CommonRiskAssessment(data?.reputationRiskAssessment);
        this.environmentRiskAssessment = new EnvironmentRiskAssessment(data?.environmentRiskAssessment);
    }

    static mapActivityUpdateModel(addEditActivity: AddEditActivity): any {
        return {
            id: addEditActivity?.id,
            activityId: addEditActivity?.activityId,
            parentActivityId: addEditActivity?.parentActivityId,
            msptActivityId: addEditActivity?.msptActivityId,
            mitigatedIrr: addEditActivity?.mitigatedIrr,
            activityTitle: addEditActivity?.activityTitle,
            activityType: addEditActivity?.activityType,
            outageId: addEditActivity?.outageId,
            plantName: addEditActivity?.plantName,
            currencyRate: addEditActivity?.currencyRate,

            maintenanceType: addEditActivity?.maintenanceType,
            riskMaintenanceId: addEditActivity?.riskMaintenanceId,
            description: addEditActivity?.description,
            lastTimeExecuted: addEditActivity?.lastTimeExecuted,
            commentOnLastExecution: addEditActivity?.commentOnLastExecution,
            aeroClassification: addEditActivity?.aeroClassification,
            plantArea: addEditActivity?.plantArea,
            procurementCategory: addEditActivity?.procurementCategory,
            mainSubSystemId: addEditActivity?.mainSubSystemId,

            estimatedGroupLifeStrategy: addEditActivity?.estimatedGroupLifeStrategy,
            planningControllingType: addEditActivity?.planningControllingType,
            oidPlannedBudget: addEditActivity?.oidPlannedBudget,
            rbsPlannedBudget: addEditActivity?.rbsPlannedBudget,
            pmlPlannedBudget: addEditActivity?.pmlPlannedBudget,
            budgetTwoYearsPrior: addEditActivity?.budgetTwoYearsPrior,
            budgetPreviousYear: addEditActivity?.budgetPreviousYear,
            budgetCurrentYear: addEditActivity?.budgetCurrentYear,
            budgetNextYear: addEditActivity?.budgetNextYear,

            isOutsideOfOutage: addEditActivity?.isOutsideOfOutage,
            hasAdviceOnExecution: addEditActivity?.hasAdviceOnExecution,
            activityTimingComment: addEditActivity?.activityTimingComment,

            overrunLikelihood: addEditActivity?.riskOfOverrun?.overrunLikelihood,
            variationDescription: addEditActivity?.riskOfOverrun?.variationDescription,
            influenceOnOutage: OverrunRisk.mapOverrunRiskModel(addEditActivity.riskOfOverrun?.influenceOnOutage),
            costToResolve: OverrunRisk.mapOverrunRiskModel(addEditActivity?.riskOfOverrun?.costToResolve),

            costRiskAssessment: CostRiskAssessment.mapCostRiskAssessmentModel(addEditActivity?.costRiskAssessment),
            safetyRiskAssessment: SafetyRiskAssessment.mapSafetyRiskAssessmentModel(addEditActivity?.safetyRiskAssessment),
            regulatoryRiskAssessment: CommonRiskAssessment.mapCommonRiskAssessmentModel(addEditActivity?.regulatoryRiskAssessment),
            reputationRiskAssessment: CommonRiskAssessment.mapCommonRiskAssessmentModel(addEditActivity?.reputationRiskAssessment),
            environmentRiskAssessment: EnvironmentRiskAssessment.mapEnvironmentRiskAssessmentModel(addEditActivity.environmentRiskAssessment),
        };
    }

    static mapSubActivityFromParent(parentActivity?: AddEditActivity): AddEditActivity {
        return new AddEditActivity({
            id: undefined,
            activityId: undefined,
            parentActivityId: parentActivity?.id,
            parentActivityName: parentActivity?.activityTitle,
            msptActivityId: parentActivity?.msptActivityId,
            mitigatedIrr: undefined,
            activityTitle: undefined,
            activityType: undefined,
            outageId: parentActivity?.outageId,

            projectGlobalId: parentActivity?.projectGlobalId,
            projectName: parentActivity?.projectName,
            maintenanceType: parentActivity?.maintenanceType,
            riskMaintenanceId: parentActivity?.riskMaintenanceId,
            description: undefined,
            lastTimeExecuted: undefined,
            commentOnLastExecution: undefined,
            aeroClassification: parentActivity?.aeroClassification,
            plantArea: parentActivity?.plantArea,
            procurementCategory: parentActivity?.procurementCategory,
            mainSubSystemId: parentActivity?.mainSubSystemId,
            mainSubSystemIndex: undefined,

            estimatedGroupLifeStrategy: parentActivity?.estimatedGroupLifeStrategy,
            planningControllingType: parentActivity?.planningControllingType,
            oidPlannedBudget: undefined,
            rbsPlannedBudget: undefined,
            pmlPlannedBudget: undefined,
            budgetTwoYearsPrior: undefined,
            budgetPreviousYear: undefined,
            budgetCurrentYear: undefined,
            budgetNextYear: undefined,
            isDifferentBudget: undefined,

            isOutsideOfOutage: false,
            hasAdviceOnExecution: true,
            activityTimingComment: undefined,

            riskOfOverrun: new RiskOfOverrun(),

            costRiskAssessment: new CostRiskAssessment(),
            safetyRiskAssessment: new SafetyRiskAssessment(),
            regulatoryRiskAssessment: new CommonRiskAssessment(),
            reputationRiskAssessment: new CommonRiskAssessment(),
            environmentRiskAssessment: new EnvironmentRiskAssessment(),
        });
    }
}
