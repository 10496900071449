import { ErrorResponse, User, UserFilterModel } from '@/models';
import store from '@/store'
import { generateQuery } from '@/utils/helpers/api-helper';
import MessageHandler from '@/utils/message-handler';
import { sdk } from '@/utils/sdk';

class UserService {
    private endpoint = 'users';

    private setIsLoading(isLoading: boolean): void {
        store.dispatch('AppModule/setIsLoading', isLoading);
    }

    public async getUsers(searchTerm: string): Promise<User[]>{
        this.setIsLoading(true);
        try {
            const userFilters: UserFilterModel = {
                page: 1,
                size: 300,
                term: searchTerm,
                sortColumn: 1,
                sortDirection: 1,
            };
            return (
                await sdk?.core.userAccessManagement.request.api.get<any>(
                    `/users${generateQuery({...userFilters})}`)
            ).data?.result?.items;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            this.setIsLoading(false);
        }
    }

    public async getParticipantsByKids(users: []): Promise<any> {
        this.setIsLoading(true);
        try {
            return await Promise.all(users.map(async (user: any) => {
                return (await sdk?.core.userAccessManagement.request.api.get<any>(`${this.endpoint}/by-kid/${user.kid}`))?.data?.result
            }));
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            this.setIsLoading(false);
        }
    }

    public async getUserByKid(kid: string): Promise<User> {
        this.setIsLoading(true);
        try {
            const user = (await sdk?.core.userAccessManagement.request.api.get<any>(`${this.endpoint}/by-kid/${kid}`))?.data?.result;
            return new User(user);
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return new User();
        } finally {
            this.setIsLoading(false);
        }
    }
}

export const userService = new UserService();
