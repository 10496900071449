export default class PersistenFilter {
    private appModule = '';

    constructor(appModule: string) {
        this.appModule = appModule;
    }

    setConfig(configKey: string, value: any): void {
        localStorage.setItem(`${this.appModule}.${configKey}`, JSON.stringify(value));
    }

    public getConfigArray(configKey: string): any {
        const filterValues = localStorage.getItem(`${this.appModule}.${configKey}`);
        if (!filterValues || filterValues == 'undefined') return [];
        return JSON.parse(filterValues);
    }

    public getConfigBoolean(configKey: string): boolean {
        const filterValues = localStorage.getItem(`${this.appModule}.${configKey}`);
        if (!filterValues || filterValues == 'undefined') return false;
        return JSON.parse(filterValues);
    }

    public getConfigNumber(configKey: string): number | undefined {
        const filterValues = localStorage.getItem(`${this.appModule}.${configKey}`);
        if (!filterValues || filterValues == 'undefined') return undefined;
        return JSON.parse(filterValues);
    }

    public getConfigString(configKey: string): string{
        const filterValues = localStorage.getItem(`${this.appModule}.${configKey}`);
        if (!filterValues || filterValues == 'undefined') return '';
        return JSON.parse(filterValues);
    }

}
