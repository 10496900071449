export interface RiskAssessmentRankingThresholdsModel {
    costRankingBands?: RankingThresholds;
    safetyRankingBands?: RankingThresholds;
    environmentRankingBands?: RankingThresholds;
    regulatoryRankingBands?: RankingThresholds;
    reputationRankingBands?: RankingThresholds;
}

export class RiskAssessmentRankingThresholds {
    costRankingThresholds?: RankingThresholds;
    safetyRankingThresholds?: RankingThresholds;
    environmentRankingThresholds?: RankingThresholds;
    regulatoryRankingThresholds?: RankingThresholds;
    reputationRankingThresholds?: RankingThresholds;

    public constructor(data?: RiskAssessmentRankingThresholdsModel) {
        this.costRankingThresholds = new RankingThresholds(data?.costRankingBands);
        this.safetyRankingThresholds = new RankingThresholds(data?.safetyRankingBands);
        this.environmentRankingThresholds = new RankingThresholds(data?.environmentRankingBands);
        this.regulatoryRankingThresholds = new RankingThresholds(data?.regulatoryRankingBands);
        this.reputationRankingThresholds = new RankingThresholds(data?.reputationRankingBands);
    }
}

export class RankingThresholds {
    low?: Threshold;
    medium?: Threshold;
    high?: Threshold;

    public constructor(data?: RankingThresholds) {
        this.low = new Threshold(data?.low);
        this.medium = new Threshold(data?.medium);
        this.high = new Threshold(data?.high);
    }
}

export class Threshold {
    startValue?: number;
    endValue?: number;

    public constructor(data?: Threshold) {
        this.startValue = data?.startValue;
        this.endValue = data?.endValue;
    }
}
