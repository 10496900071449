import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class AppModule extends VuexModule {
  public isLoading = false;
  private loadingCounter = 0;

  @Mutation
  public loading(isLoading: boolean): void {
      this.loadingCounter = isLoading ? this.loadingCounter + 1 : this.loadingCounter - 1;
      this.isLoading = this.loadingCounter === 0 ? false : true;
  }

  @Action
  public setIsLoading(isLoading: boolean): void {
      this.context.commit('loading', isLoading);
  }
}
export default AppModule;
