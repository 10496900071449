import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { UserProfile } from '@/models';
import PermissionNames from '@/utils/permission-names';
import { sdk } from '@/utils/sdk';

@Module({ namespaced: true })
class UserModule extends VuexModule {
    userProfile: UserProfile | null = null;

    get userName(): string | undefined {
        return this.userProfile?.name;
    }

    get userEmail(): string | undefined {
        return this.userProfile?.email;
    }

    get permissions(): string[] | undefined {
        return this.userProfile?.permissions;
    }

    get hasReadOnlyPermission(): boolean {
        return !!this.userProfile?.permissions?.includes(PermissionNames.ReadOnlyView);
    }

    get hasManageOutagePermission(): boolean {
        return !!this.userProfile?.permissions?.includes(PermissionNames.ManageOutage);
    }

    get hasPrioritizePermission(): boolean {
        return !!this.userProfile?.permissions?.includes(PermissionNames.Prioritize);
    }

    get hasGenerateRbsPermission(): boolean {
        return !!this.userProfile?.permissions?.includes(PermissionNames.GenerateRbs);
    }

    @Mutation
    public setUserProfileData(userProfile: UserProfile): void {
        this.userProfile = userProfile;
    }

    @Action
    public async loadUserProfile(): Promise<void> {
        const permissions: any = sdk?.localStorage.getPermissionsLocalStorage();
        const permissionNames = permissions.permissions?.map((permission: any) => permission.name);
        const userProfile = new UserProfile(permissions?.name, permissions?.email, permissionNames);
        this.context.commit('setUserProfileData', userProfile);

        if (!this.hasReadOnlyPermission) {
            window.location.href = `${window.location.origin}/authorization/access-forbidden.html`;
        }
    }
}
export default UserModule;
