import { ActivityDurationOverrunRisk } from './activity-duration-overrun-risk';
export class RiskOfOverrun {
    overrunProbability?: number;
    totalCostOverrunRisk?: number;
    estimatedCostOverrunRisk?: number;
    activities?: ActivityDurationOverrunRisk[]

    public constructor(data?: RiskOfOverrun) {
        this.overrunProbability = data?.overrunProbability;
        this.totalCostOverrunRisk = data?.totalCostOverrunRisk;
        this.estimatedCostOverrunRisk = data?.estimatedCostOverrunRisk;
        this.activities = data?.activities;
    }
}
