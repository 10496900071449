import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Technology, PowerPlant, Unit, FilterQueryParam } from '@/models';
import {  technologyService, powerPlantService, unitService } from '@/services';
import moment from 'moment';
import PowerPlantHelper from '@/utils/helpers/power-plant-helper';

import { configKeys } from '@/utils/local-storage/config-keys';
import PersistentFilter from '@/utils/local-storage/persistent-filter';

const persistentFilter = new PersistentFilter(configKeys.OUTAGES_OVERVIEW);

@Module({ namespaced: true })
class HomeModule extends VuexModule {
    public technologies: Technology[] = [];
    public selectedTechnologies: number[] = persistentFilter.getConfigArray(configKeys.OUTAGE_TECHNOLOGY_IDS);
    public powerPlants: PowerPlant[] = [];
    public selectedPowerPlants: number[] = persistentFilter.getConfigArray(configKeys.OUTAGE_POWERPLANT_IDS);
    public units: Unit[] = [];
    public selectedUnits: number[] = persistentFilter.getConfigArray(configKeys.OUTAGE_UNIT_IDS);
    public searchTerm = '';
    public year: number = moment().year();

    @Mutation
    public setTechnologies(technologies: Technology[]): void {
        this.technologies = technologies;
    }

    @Mutation
    public setSelectedTechnologiesMutation(selectedTechnologies: number[]): void {
        this.selectedTechnologies = selectedTechnologies;
    }

    @Mutation
    public resetSelectedTechnologiesArray(): void {
        this.selectedTechnologies = [];
    }

    @Mutation
    public setPowerPlants(powerPlants: PowerPlant[]): void {
        this.powerPlants = powerPlants;
    }

    @Mutation
    public setSelectedPowerPlantsMutation(selectedPowerPlants: number[]): void {
        this.selectedPowerPlants = selectedPowerPlants;
    }

    @Mutation
    public resetPowerPlantsArrays(): void {
        this.powerPlants = [];
        this.selectedPowerPlants = [];
    }

    @Mutation
    public setUnits(units: Unit[]): void {
        this.units = units;
    }

    @Mutation
    public setSelectedUnitsMutation(selectedUnits: number[]): void {
        this.selectedUnits = selectedUnits;
    }

    @Mutation
    public resetUnitsArrays(): void {
        this.units = [];
        this.selectedUnits = [];
    }

    @Mutation
    public setSearchTermValue(searchTerm: string): void {
        this.searchTerm = searchTerm;
    }

    @Mutation
    public setYearValue(year: number): void {
        this.year = year;
    }

    @Action
    public async getTechnologies(): Promise<void> {
        const technologies = await technologyService.getTechonologies();
        this.context.commit('setTechnologies', technologies);
    }

    @Action
    public setSelectedTechnologies(selectedTechnologies: number[]): void {
        this.context.commit('setSelectedTechnologiesMutation', selectedTechnologies);
    }

    @Action
    public resetSelectedTechnologies(): void {
        this.context.commit('resetSelectedTechnologiesArray');
    }

    @Action
    public async getPowerPlants(params: FilterQueryParam): Promise<void> {
        let powerPlants = (await powerPlantService.getPowerPlants(params))
            .sort((a, b) => (a?.businessName ?? '').localeCompare(b?.businessName ?? ''))

        powerPlants = PowerPlantHelper.removeIrschingDuplicatesIfExists(powerPlants);
        this.context.commit('setPowerPlants', powerPlants);
    }

    @Action
    public setSelectedPowerPlants(selectedPowerPlants: number[]): void {
        this.context.commit('setSelectedPowerPlantsMutation', selectedPowerPlants);
    }

    @Action
    public resetPowerPlants(): void {
        this.context.commit('resetPowerPlantsArrays');
    }

    @Action
    public async getUnits(params: FilterQueryParam): Promise<void> {
        const units = await unitService.getUnits(params);
        this.context.commit('setUnits', units);
    }

    @Action
    public setSelectedUnits(selectedUnits: number[]): void {
        this.context.commit('setSelectedUnitsMutation', selectedUnits);
    }

    @Action
    public resetUnits(): void {
        this.context.commit('resetUnitsArrays');
    }

    @Action
    public setSearchTerm(searchTerm: string): void {
        this.context.commit('setSearchTermValue', searchTerm);
    }

    @Action
    public setYear(year: number): void {
        this.context.commit('setYearValue', year);
    }

}

export default HomeModule;
