import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { RiskAssessmentRankingThresholds } from '@/models/add-edit-activity/risk-assessment-ranking-thresholds';
import { activityService } from '@/services';


@Module({ namespaced: true })
class OutageDetailsModule extends VuexModule {
    public unitId?: number;
    public capacity?: number;
    public globalId?: number;
    public technologyId?: number;
    public plantName?: string;
    public currencyRate?: number;
    public currency?: string;
    public riskAssessmentRankingThresholds?: RiskAssessmentRankingThresholds = new RiskAssessmentRankingThresholds();
    public startDate?: Date;

    @Mutation
    public setUnitIdMutation(unitId?: number): void {
        this.unitId = unitId;
    }

    @Mutation
    public setCapacityMutation(capacity?: number): void {
        this.capacity = capacity;
    }

    @Mutation
    public setGlobalIdMutation(globalId?: number): void {
        this.globalId = globalId;
    }

    @Mutation
    public setTechnologyIdMutation(technologyId?: number): void {
        this.technologyId = technologyId;
    }

    @Mutation
    public setPlantNameMutation(plantName?: string): void {
        this.plantName = plantName;
    }

    @Mutation
    public setCurrencyRateMutation(currencyRate?: number): void {
        this.currencyRate = currencyRate;
    }

    @Mutation
    public setRiskAssessmentRankingThresholds(riskAssessmentRankingThresholds?: RiskAssessmentRankingThresholds): void {
        this.riskAssessmentRankingThresholds = riskAssessmentRankingThresholds;
    }

    @Mutation
    public setCurrencyMutation(currency?: string): void {
        this.currency = currency;
    }

    @Mutation
    public setStartDateMutation(startDate?: Date): void {
        this.startDate = startDate;
    }

    @Action
    public setUnitId(unitId?: number): void {
        this.context.commit('setUnitIdMutation', unitId);
    }

    @Action
    public setCapacity(capacity?: number): void {
        this.context.commit('setCapacityMutation', capacity);
    }

    @Action
    public setGlobalId(globalId?: number): void {
        this.context.commit('setGlobalIdMutation', globalId);
    }

    @Action
    public setTechnologyId(technologyId?: number): void {
        this.context.commit('setTechnologyIdMutation', technologyId);
    }

    @Action
    public setPlantName(plantName?: number): void {
        this.context.commit('setPlantNameMutation', plantName);
    }

    @Action
    public setCurrencyRate(currencyRate?: number): void {
        this.context.commit('setCurrencyRateMutation', currencyRate);
    }

    @Action
    public async loadRiskAssessmentRankingThresholds(): Promise<void> {
        const riskAssessmentRankingThresholds = await activityService.getRiskAssessmentRankingThresholds(this.technologyId);
        this.context.commit('setRiskAssessmentRankingThresholds', riskAssessmentRankingThresholds);
    }

    @Action
    public setCurrency(currency?: string): void {
        if (currency) {
            this.context.commit('setCurrencyMutation', currency);
        }
        else {
            this.context.commit('setCurrencyMutation', '');
        }
    }

    @Action
    public setStartDate(startDate?: Date): void {
        this.context.commit('setStartDateMutation', startDate);
    }

}
export default OutageDetailsModule;
