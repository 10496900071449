import { CostRiskAssessmentRow } from './cost-risk-assessment-row';
export class CostRiskAssessment {
    activityRiskAssessmentComment?: string;
    riskAssessmentBefore?: CostRiskAssessmentRow;
    riskAssessmentAfter?: CostRiskAssessmentRow;

    public constructor(data?: any) {
        this.activityRiskAssessmentComment = data?.activityRiskAssessmentComment;
        this.riskAssessmentBefore = new CostRiskAssessmentRow(data?.riskAssessmentBefore);
        this.riskAssessmentAfter = new CostRiskAssessmentRow(data?.riskAssessmentAfter);
    }

    public static mapCostRiskAssessmentModel(costRiskAssessment?: CostRiskAssessment): CostRiskAssessment {
        return new CostRiskAssessment({
            activityRiskAssessmentComment: costRiskAssessment?.activityRiskAssessmentComment,
            riskAssessmentBefore: CostRiskAssessmentRow.mapCostRiskAssessmentRowModel(costRiskAssessment?.riskAssessmentBefore),
            riskAssessmentAfter: CostRiskAssessmentRow.mapCostRiskAssessmentRowModel(costRiskAssessment?.riskAssessmentAfter),
        });
    }
}
