import { OutageFile } from './outage-file';
import { RiskOfOverrun } from './risk-of-overrun';

export interface OutageRbsBudgetsModel {
    totalBudget?: number | undefined;
    twoYearsPriorBudget?: number | undefined;
    lastYearBudget?: number | undefined;
    currentYearBudget?: number | undefined;
    nextYearBudget?: number | undefined;
}

export interface ParticipantModel {
    kid?: string | undefined;
    name?: string | undefined;
}

export interface OutageGetResponse {
    outageId?: string | undefined;
    plantName?: string | undefined;
    plantId?: number;
    unitId?: number;
    unitName?: string | undefined;
    capacity?: number | undefined;
    globalId?: number | undefined;
    startDate?: Date;
    endDate?: Date;
    currency?: string | undefined;
    currencyRate?: number;
    rbsBudget?: OutageRbsBudgetsModel;
    oidPlannedBudget?: number | undefined;
    pmlPlannedBudget?: number | undefined;
    readonly isDifferentBudget?: boolean;
    technologyId?: number | undefined;
    hideRbsFunctionality?: boolean;
    participants?: ParticipantModel[] | undefined;
    files?: OutageFile[] | undefined;
    riskOfOverrun?: RiskOfOverrun;
}

export class RbsBudget {
    totalBudget?: number;
    twoYearsPriorBudget?: number;
    lastYearBudget?: number;
    currentYearBudget?: number;
    nextYearBudget?: number;

    public constructor(data?: RbsBudget) {
        this.totalBudget = data?.totalBudget;
        this.twoYearsPriorBudget = data?.twoYearsPriorBudget;
        this.lastYearBudget = data?.lastYearBudget;
        this.currentYearBudget = data?.currentYearBudget;
        this.nextYearBudget = data?.nextYearBudget;
    }
}
