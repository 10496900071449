import { Budget } from './budget';
import { OutageRbsBudgetsModel } from './rbs-budget';
import { RiskOfOverrun } from './risk-of-overrun';

export interface OutageBudgetsModel {
    rbsBudget?: OutageRbsBudgetsModel;
    pmlPlannedBudget?: number | undefined;
}

export interface OutageBudgetsAndRiskOfOverrunGetResponse {
    budgets?: OutageBudgetsModel;
    overrunRisk?: RiskOfOverrun;
    readonly isDifferentBudget?: boolean;
    oidPlannedBudget?: number | undefined;
}

export class OutageBudgetAndRisk {
    budgets?: Budget;
    overrunRisk?: RiskOfOverrun;
    isDifferentBudget?: boolean;
    oidPlannedBudget?: number;

    public constructor(data?: OutageBudgetsAndRiskOfOverrunGetResponse) {
        const {isDifferentBudget, oidPlannedBudget} = data ?? {};
        const {rbsBudget, pmlPlannedBudget} = data?.budgets ?? {};
        const budget = {
            rbsBudget,
            pmlPlannedBudget,
            isDifferentBudget,
            oidPlannedBudget
        };
        this.budgets = new Budget(budget);
        this.overrunRisk = new RiskOfOverrun(data?.overrunRisk);
    }
}
