import { OutageGetResponse, RbsBudget } from './rbs-budget';

export class Budget {
    rbsPlannedBudget?: number; // not used anymore
    oidPlannedBudget?: number;
    pmlPlannedBudget?: number;
    isDifferentBudget?: boolean;
    rbsBudget?: RbsBudget;

    public constructor(data?: OutageGetResponse | any) {
        this.rbsPlannedBudget = data?.rbsBudget?.totalBudget;
        this.oidPlannedBudget = data?.oidPlannedBudget;
        this.pmlPlannedBudget = data?.pmlPlannedBudget;
        this.isDifferentBudget = data?.isDifferentBudget;
        this.rbsBudget = new RbsBudget(data?.rbsBudget);
    }
}
