import { AddEditActivity } from '@/models';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class ActivityModule extends VuexModule {
    public initialAddEditActivity = '';
    public initialRiskOfOverrun = '';
    public initialCostRiskAssessment = '';
    public initialSafetyRiskAssessment = '';
    public initialregulatoryRiskAssessment = '';
    public initialReputationRiskAssessment = '';
    public initialEnvironmentRiskAssessment = '';

    @Mutation
    public setInitialActivityMutation(initialAddEditActivity: AddEditActivity): void {
        this.initialAddEditActivity = JSON.stringify(initialAddEditActivity);
        this.initialRiskOfOverrun = JSON.stringify(initialAddEditActivity?.riskOfOverrun);
        this.initialCostRiskAssessment = JSON.stringify(initialAddEditActivity?.costRiskAssessment);
        this.initialSafetyRiskAssessment = JSON.stringify(initialAddEditActivity?.safetyRiskAssessment);
        this.initialregulatoryRiskAssessment = JSON.stringify(initialAddEditActivity?.regulatoryRiskAssessment);
        this.initialReputationRiskAssessment = JSON.stringify(initialAddEditActivity?.reputationRiskAssessment);
        this.initialEnvironmentRiskAssessment = JSON.stringify(initialAddEditActivity?.environmentRiskAssessment);
    }

    @Action
    public setInitialActivityDetails(initialAddEditActivity: AddEditActivity): void {
        this.context.commit('setInitialActivityMutation', initialAddEditActivity);
    }
}
export default ActivityModule;
