import { PowerPlant, FilterQueryParam } from '@/models';
import MessageHandler from '@/utils/message-handler';
import { ErrorResponse } from '@/models';
import { setIsLoading } from '@/utils/helpers/app-loading';
import { sdk } from '@/utils/sdk';
import { generateQuery } from '@/utils/helpers/api-helper';

class PowerPlantService {
    private endpoint = 'power-plants/module';

    public async getPowerPlants(params: FilterQueryParam): Promise<PowerPlant[]> {
        setIsLoading(true);
        try {
            const moduleId = process.env.VUE_APP_MODULE_ID;

            return (await sdk?.core.masterData.request.api
                .get<any>(
                    `${this.endpoint}/${moduleId}${generateQuery({fleetIds: params?.technologyIds})}`,
                ))?.data?.result?.items;
        }
        catch (error) {
            MessageHandler.showErrorMessage(error as ErrorResponse);
            return [];
        } finally {
            setIsLoading(false);
        }
    }
}

export const powerPlantService = new PowerPlantService();
